import React, { useMemo, useCallback, useEffect } from 'react'
import { EntitySubType } from '@common/interfaces/entityInfo'
import Autocomplete from '../../../components/Common/Autocomplete'
import Box from '@mui/material/Box'
import cn from 'classnames'
import styles from '../CollectionsChecksPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ICheck } from '@common/interfaces/collection'
import TableRow from '../../../components/Common/TableRow'
import TableCell from '../../../components/Common/TableCell'
import { formatPrice } from '../../../helpers/helpers'
import CreatableSelectField, { IOptionType } from '../../../components/Common/CreatableSelectField'
import { MenuIcon } from '../../../components/Common/Icons'

interface IProps {
  check: ICheck
  index: number
  activeItems: number[]
  activeItem: number
  handleSelectRow: (event: React.MouseEvent, index: number) => void
  handleUpdateCheck: (check: object) => void
  values: any
  form: any
  name: string
  clientsOptions: IOptionType[]
  loadDebtors: (inputValue: string) => Promise<IOptionType[]>
  handleAddDebtor: (debtorName: string, clientName: string) => Promise<any>
  handleClickMenu: (check: ICheck, event: React.MouseEvent<HTMLElement>) => void
}

const CheckRow = ({
  check,
  index,
  activeItems,
  activeItem,
  handleSelectRow,
  handleUpdateCheck,
  values,
  name,
  clientsOptions,
  loadDebtors,
  handleAddDebtor,
  handleClickMenu,
}: IProps) => {
  const isBillPaymentProvider = useMemo(
    () => check?.checkAccount?.entityInfo?.type === EntitySubType.BillPaymentProvider,

    [check],
  )
  const isValidRow = useMemo(
    () =>
      !!check?.clientInfo &&
      !!check?.accountNumber &&
      !!check?.checkAccount?.linkedName &&
      (!isBillPaymentProvider || !!check?.originalEntity),
    [check, isBillPaymentProvider],
  )

  const handleChangeDebtor = useCallback(
    async (event, newValue: any) => {
      if (!newValue) {
        handleUpdateCheck({
          id: check.id,
          debtor: '',
        })
      } else if (newValue.id) {
        handleUpdateCheck({
          id: check.id,
          debtor: newValue?.value,
        })
      } else if (newValue.value) {
        const result = await handleAddDebtor(newValue.value, values.checks[index].clientName)
        if (!!result?.error) {
          return
        }
        handleUpdateCheck({
          id: check.id,
          debtor: newValue?.value,
        })
      }
    },
    [check, handleAddDebtor, handleUpdateCheck, values, index],
  )

  const handleChangeOriginalEntity = useCallback(
    async (event, newValue: any) => {
      if (!newValue) {
        handleUpdateCheck({
          id: check.id,
          originalEntity: '',
        })
      } else if (newValue.id) {
        handleUpdateCheck({
          id: check.id,
          originalEntity: newValue?.value,
        })
      } else if (newValue.value) {
        const result = await handleAddDebtor(newValue.value, values.checks[index].clientName)
        if (!!result?.error) {
          return
        }
        handleUpdateCheck({
          id: check.id,
          originalEntity: newValue?.value,
        })
      }
    },
    [check, handleAddDebtor, handleUpdateCheck, values, index],
  )

  const handleUpdateClientName = useCallback(
    (event, value: any) => {
      handleUpdateCheck({
        id: check.id,
        clientName: typeof value === 'string' ? value : value?.value,
      })
    },
    [check, handleUpdateCheck],
  )

  const isActiveRow = useMemo(() => activeItems.includes(index), [activeItems, index])
  const [activeField, setActiveField] = React.useState<string | null>(null)

  const handleSelectCell = useCallback((event, field) => {
    setActiveField(field)
  }, [])

  const clientInputRef = React.useRef<HTMLInputElement>(null)
  const customerInputRef = React.useRef<HTMLInputElement>(null)
  const originalEntityInputRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isActiveRow) {
      if (activeField === `${name}.clientName`) {
        clientInputRef.current?.focus()
      } else if (activeField === `${name}.checkAccount`) {
        customerInputRef.current?.focus()
      } else if (activeField === `${name}.originalEntity`) {
        originalEntityInputRef.current?.focus()
      }
    }
  }, [isActiveRow, activeField, name])

  if (!check) {
    return null
  }

  return (
    <TableRow
      id={`mapping-table-row-${index}`}
      key={`check-list-row-${check.id}`}
      data-index={index}
      className={cn('activableRow', {
        activeRow: isActiveRow,
        currentActiveRow: activeItem === index,
        [styles.validRow]: isValidRow,
      })}
      onClick={(event) => handleSelectRow(event, index)}
    >
      <TableCell className={genericSs.tableTextRight}>{check.checkNumber}</TableCell>
      <TableCell
        className={genericSs.tableTextLeft}
        onClick={(event) => handleSelectCell(event, `${name}.clientName`)}
      >
        {isActiveRow ? (
          <Autocomplete
            ref={clientInputRef}
            clearIcon={null}
            label=""
            className={cn({
              focusableInput: true,
              [styles.validInput]: !!check.clientInfo,
              invalidInput: !check.clientInfo,
            })}
            name={`${name}.clientName`}
            placeholder="Select client"
            tabIndex={2 * index}
            value={values?.checks?.[index]?.clientName || null}
            options={clientsOptions}
            getOptionValue={(option) => option.value}
            onChange={handleUpdateClientName}
          />
        ) : (
          check.clientName || <div className={styles.selectCell}>Select client</div>
        )}
      </TableCell>
      <TableCell
        className={genericSs.tableTextLeft}
        onClick={(event) => handleSelectCell(event, `${name}.checkAccount`)}
      >
        {isActiveRow ? (
          <CreatableSelectField
            ref={customerInputRef}
            label=""
            name={`${name}.checkAccount`}
            tabIndex={2 * index + 1}
            className={cn({
              focusableInput: true,
              [styles.validInput]: !!check.accountNumber && !!check.checkAccount?.linkedName,
              invalidInput: !check.accountNumber || !check.checkAccount?.linkedName,
            })}
            placeholder="Select customer"
            onAddValue={(debtorName) =>
              handleAddDebtor(debtorName, values.checks[index].clientName)
            }
            options={[]}
            getOptionValue={(option) => option.value}
            isAsync
            loadOptions={loadDebtors}
            onChangeCustom={handleChangeDebtor}
          />
        ) : (
          check.checkAccount?.linkedName || <div className={styles.selectCell}>Select customer</div>
        )}
      </TableCell>
      <TableCell
        className={genericSs.tableTextLeft}
        onClick={(event) => handleSelectCell(event, `${name}.originalEntity`)}
      >
        {isBillPaymentProvider && isActiveRow ? (
          <CreatableSelectField
            ref={originalEntityInputRef}
            label=""
            name={`${name}.originalEntity`}
            tabIndex={2 * index + 1}
            className={cn({
              focusableInput: true,
              [styles.validInput]:
                !!check.accountNumber && !!check.checkAccount?.linkedName && !!check.originalEntity,
              invalidInput:
                !check.accountNumber || !check.checkAccount?.linkedName || !check.originalEntity,
            })}
            placeholder="Select original customer"
            onAddValue={(debtorName) =>
              handleAddDebtor(debtorName, values.checks[index].clientName)
            }
            options={[]}
            getOptionValue={(option) => option.value}
            isAsync
            loadOptions={loadDebtors}
            onChangeCustom={handleChangeOriginalEntity}
          />
        ) : isBillPaymentProvider ? (
          check.originalEntity || <div className={styles.selectCell}>Select original customer</div>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell className={genericSs.tableTextRight}>
        <span className={genericSs.pricePrefix}>$</span>
        {formatPrice(check.paymentAmount)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="inline-box" ml={1}>
          <MenuIcon onClick={(event) => handleClickMenu(check, event)} size="small" />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default CheckRow
