import { ICheck } from '@common/interfaces/collection'
import { Paper } from '@mui/material'
import React, { useEffect, useMemo, useRef } from 'react'
import styles from '../CollectionsChecksPage.module.scss'
import { ReactComponent as NavigateNext } from '../../../assets/images/next-arrow.svg'
import { ReactComponent as NavigateBefore } from '../../../assets/images/prev-arrow.svg'
import Carousel from 'react-material-ui-carousel'
import imageStore from '../../../helpers/imageStore'
import { retryPromise } from '@common/helpers/helpers'
import TableContainer from '../../../components/Common/TableContainer'
import Table from '../../../components/Common/Table'
import TableHead from '../../../components/Common/TableHead'
import TableRow from '../../../components/Common/TableRow'
import TableCell from '../../../components/Common/TableCell'
import TableBody from '../../../components/Common/TableBody'
import { EntitySubType } from '@common/interfaces/entityInfo'

const CheckImage = ({
  check,
  shouldLoad,
  handleLoadCheckImage,
}: {
  check: ICheck
  shouldLoad?: boolean
  handleLoadCheckImage: (id: string) => Promise<string>
}) => {
  const itemRef = useRef(null)

  useEffect(() => {
    const getSource = async () => {
      if (!itemRef.current || !check.id || !shouldLoad) {
        return
      }

      try {
        let img = await imageStore.get(check.id)
        if (!img) {
          const response = await retryPromise(handleLoadCheckImage(check.id), 3)
          if (response.data) {
            img = response.data
            await imageStore.set(check.id, img)

            if (itemRef.current && !itemRef.current?.firstChild) {
              const outputImg = document.createElement('img')
              outputImg.src = 'data:image/png;base64,' + img
              itemRef.current.appendChild(outputImg)
            }
          }
        }
      } catch (err) {}
    }

    getSource()
    // delete the images from the store when the component is unmounted
    return () => {
      imageStore.del(check.id)
    }
  }, [check, shouldLoad, handleLoadCheckImage])

  const isBillPaymentProvider = useMemo(
    () => check?.checkAccount?.entityInfo?.type === EntitySubType.BillPaymentProvider,
    [check],
  )

  return (
    <>
      <div className={styles.titleSection}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client Name</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Amount</TableCell>
                {isBillPaymentProvider && <TableCell>Original Entity</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{check?.clientName}</TableCell>
                <TableCell>{check?.checkAccount?.entityInfo?.name}</TableCell>
                <TableCell>{check?.paymentAmount}</TableCell>
                {isBillPaymentProvider && <TableCell>{check?.originalEntity}</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Paper ref={itemRef} className={styles.carouselItem} elevation={10} />
    </>
  )
}

interface IProps {
  checksToLoad: number[]
  activeItems: number[]
  setActiveItems: React.Dispatch<React.SetStateAction<number[]>>
  checks: ICheck[]
  handleLoadCheckImage: (id: string) => Promise<string>
}

const CheckImageCarousel = ({
  handleLoadCheckImage,
  checksToLoad,
  activeItems,
  setActiveItems,
  checks,
}: IProps) => {
  const handleChangeIndex = (index: number) => {
    setActiveItems((indexes) => (indexes.includes(index) ? indexes : [index]))
  }

  return (
    <div className={styles.carouselWrapper}>
      <Carousel
        className={styles.carousel}
        autoPlay={false}
        duration={0}
        animation="slide"
        indicators
        navButtonsAlwaysVisible
        navButtonsWrapperProps={{ className: styles.carouselNavButton, style: {} }}
        indicatorContainerProps={{
          className: styles.carouselIndicators,
          style: {},
        }}
        indicatorIconButtonProps={{
          className: styles.carouselIndicatorsItem,
          style: {},
        }}
        activeIndicatorIconButtonProps={{
          className: styles.carouselIndicatorsItemActive,
          style: {},
        }}
        PrevIcon={<NavigateBefore />}
        NextIcon={<NavigateNext />}
        index={activeItems[0]}
        onChange={handleChangeIndex}
        changeOnFirstRender
      >
        {checks?.map((check, index) => (
          <CheckImage
            check={check}
            key={`check-img-${check.id}`}
            handleLoadCheckImage={handleLoadCheckImage}
            shouldLoad={checksToLoad.includes(index)}
          />
        ))}
      </Carousel>
    </div>
  )
}

export default CheckImageCarousel
