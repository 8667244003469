import { all } from 'redux-saga/effects'
import { History } from 'history'
import {
  loginSuccess,
  logoutSuccess,
  profileSuccess,
  onCheckPasswordSetup,
  permissionDenied,
  loginAsClientSuccess,
  logoutAsClientSuccess,
} from './profile/sagas'
import {
  createSuccess as createClientInfoSuccess,
  updateSuccess as updateClientInfoSuccess,
  exportClientAggregation,
  exportClientFeeStatement,
  loadOveradvances,
  createRequireReportSuccess,
  repullClientInfo,
  terminateSuccess,
  exportPortfolioReview,
  exportRiskRatingSummary,
  exportMasterInventory,
} from './clientInfo/sagas'
import {
  uploadChecksSuccess,
  addCheckAccountSuccess,
  updateWireSuccess,
  updateMidDayWireSuccess,
  submitCollectionSuccess,
  exportAggregation,
  pullMidDayTotals,
  repullWiresFilesAndStatus,
  refreshWiresAfterDelete,
  repullChecksInfo,
} from './collections/sagas'
import {
  exportCustomerAggregation,
  exportVendorAggregation,
  exportInvestorSummary,
} from './entityInfo/sagas'
import { listFailure } from './user/sagas'
import { updateFeeSuccess } from './fee/sagas'
import { updateAdjustmentSuccess } from './adjustment/sagas'
import {
  exportLoanBalanceTransactions,
  downloadAverageLoanBalanceResult,
  exportEndOfMonthFlow,
  reloadLatestEndOfMonthFlow,
} from './loanBalance/sagas'
import {
  bbcUpdateSuccess,
  bbcFilesSubmitSuccess,
  recalculateClientCollateral,
  refetchBBCReserves,
  refetchCustomRules,
  refetchCustomRulesLabels,
  calculateClientCollateralSuccess,
  downloadBBCExport,
  downloadTemplate,
  exportARRollforward,
  exportAPRollforward,
  bbcFlagUpdate,
} from './bbc/sagas'
import { exportAuditLog } from './auditLog/sagas'
import { notify } from './ui/sagas'
import { IApi } from '../api'
import {
  updateOngoingReportingSuccess,
  submitOngoingReportingSuccess,
  exportSalesBySkuVisualization,
} from './ongoingReporting/sagas'
import { exportNotes, hideNotesSaga } from './notes/sagas'
import {
  createSuccess as createProspectSuccess,
  createProspectTermSuccess,
  generateTermSheetSuccess,
  updateOPSReporting,
  refreshOpsFlags,
} from './prospect/sagas'
import { refreshOngoingReporting } from './generalLedger/sagas'
import { addEntitySuccess, updateEntityInfoSuccess } from './entityInfo/sagas'
import {
  refreshData as refreshDueDiligenceData,
  refreshDocuments as refreshDueDiligenceDocuments,
  processDocumentsSuccess as processDueDiligenceDocumentsSuccess,
  profileSuccess as profileDueDiligenceDocumentsSuccess,
  documentRequestUploadSuccess as dueDiligenceDocumentRequestUploadSuccess,
  updateTeamMemberSuccess as dueDiligenceUpdateTeamMemberSuccess,
  getDocumentRequestsSharedLink as dueDiligenceGetDocumentRequestsSharedLink,
  runBBCSuccess as dueDiligenceRunBBCSuccess,
  updateDueDiligenceInfo as dueDiligenceUpdateInfo,
} from './dueDiligence/sagas'
import { clientIntakeRedirect } from './clientIntake/sagas'
import { exportParticipationActivity } from './participant/sagas'
import { repullNewFlagCount } from './flag/sagas'
import { exportBankTransactions } from './bankTransactions/sagas'

export default function* rootSaga(history: History, api: IApi) {
  yield all([
    loginSuccess(history, api),
    logoutSuccess(history, api),
    profileSuccess(history),
    onCheckPasswordSetup(history),
    permissionDenied(history),
    listFailure(history),
    createClientInfoSuccess(history),
    updateClientInfoSuccess(),
    exportClientAggregation(api),
    exportClientFeeStatement(),
    downloadTemplate(),
    loadOveradvances(),
    createRequireReportSuccess(),
    repullClientInfo(),
    terminateSuccess(history),
    uploadChecksSuccess(api),
    addCheckAccountSuccess(),
    updateWireSuccess(),
    updateMidDayWireSuccess(),
    submitCollectionSuccess(history),
    exportAggregation(),
    updateFeeSuccess(),
    updateAdjustmentSuccess(),
    exportLoanBalanceTransactions(),
    downloadAverageLoanBalanceResult(),
    exportEndOfMonthFlow(),
    reloadLatestEndOfMonthFlow(),
    bbcUpdateSuccess(),
    bbcFilesSubmitSuccess(),
    bbcFlagUpdate(),
    recalculateClientCollateral(),
    refetchBBCReserves(),
    refetchCustomRules(),
    refetchCustomRulesLabels(),
    calculateClientCollateralSuccess(),
    downloadBBCExport(),
    exportAuditLog(api),
    notify(),
    updateOngoingReportingSuccess(),
    submitOngoingReportingSuccess(history),
    createProspectSuccess(history),
    exportARRollforward(),
    exportAPRollforward(),
    refreshOngoingReporting(),
    addEntitySuccess(),
    pullMidDayTotals(),
    repullWiresFilesAndStatus(),
    refreshWiresAfterDelete(),
    updateEntityInfoSuccess(history),
    exportPortfolioReview(api),
    exportCustomerAggregation(),
    exportVendorAggregation(),
    exportInvestorSummary(),
    refreshDueDiligenceData(),
    refreshDueDiligenceDocuments(),
    processDueDiligenceDocumentsSuccess(history),
    profileDueDiligenceDocumentsSuccess(),
    dueDiligenceDocumentRequestUploadSuccess(),
    dueDiligenceUpdateTeamMemberSuccess(),
    dueDiligenceGetDocumentRequestsSharedLink(),
    dueDiligenceRunBBCSuccess(history),
    dueDiligenceUpdateInfo(),
    createProspectTermSuccess(history),
    clientIntakeRedirect(history),
    generateTermSheetSuccess(),
    updateOPSReporting(),
    exportRiskRatingSummary(),
    exportSalesBySkuVisualization(),
    exportNotes(),
    exportParticipationActivity(),
    loginAsClientSuccess(api),
    logoutAsClientSuccess(api),
    exportMasterInventory(),
    hideNotesSaga(),
    refreshOpsFlags(),
    repullNewFlagCount(),
    exportBankTransactions(),
    repullChecksInfo(),
  ])
}
